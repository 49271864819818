import React, { useEffect, useState } from "react";
import classNames from "./registration.module.scss";

//assets
import logoWithName from "../../assets/images/logowithname.svg";
import {
  CustomTextInput,
  EachCustomDropdown,
  EachCustomDropdownCountry,
  MyEachCustomImageUpload,
} from "../../components/Custom";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { getData } from "../../assets/functions/apiCalls";
import countryCodes from "../../assets/data/countryCode.json";

const RegistrationNew = () => {
  const history = useHistory();
  const [allCountries, setAllCountries] = useState([]);
  const [usernameAvailable, setUsernameAvailable] = useState(false);
  const [registrationDetails, setRegistrationDetails] = useState({});
  const [registrationDetailsFinaceverse, setRegistrationDetailsFinaceverse] =
    useState({});
  const [registrationStep, setRegistrationStep] = useState(1); //default 1
  const [localLoading, setLocalLoading] = useState(false);

  //functions

  async function newRegistrationAPI() {
    if (registrationDetails.password !== registrationDetails.confirmPassword) {
      return toast.error("Password doesn't match!");
    }
    setLocalLoading(true);
    delete registrationDetails.confirmPassword;
    try {
      let response = await axios.post(
        "https://newauth.apimachine.com/v1/user/register",
        { ...registrationDetails, appCode: "finace" }
      );
      if (response?.data?.status) {
        setRegistrationStep(2);
      } else {
        toast.error("Error while registering new user!");
      }
      setLocalLoading(false);
    } catch (error) {
      setLocalLoading(false);
      toast.error("Error while registering new user!");
      console.log(error?.message, "New registration api error");
    }
  }

  async function createFinaceverseProfile() {
    setLocalLoading(true);
    try {
      let response = await axios.post(
        "https://finacebackend.marketsverse.com/api/registeruser",
        {
          username: registrationDetails?.userName,
          name: registrationDetails?.firstName,
          lastname: registrationDetails?.lastName,
          email: registrationDetails?.email,
          ProfilePic: registrationDetailsFinaceverse?.ProfilePic,
          Country: registrationDetailsFinaceverse?.Country,
          Address: registrationDetailsFinaceverse?.Address,
          pincode: registrationDetailsFinaceverse?.pincode,
          state: registrationDetailsFinaceverse?.state,
          city: registrationDetailsFinaceverse?.city,
          PhoneNumber: registrationDetailsFinaceverse?.PhoneNumber,
        }
      );
      if (response?.data?.success) {
        toast.success(response?.data?.message);
        history.push("/login");
      } else {
        toast.error("Error while creating finaceverse profile!");
      }
      console.log(response);
      setLocalLoading(false);
    } catch (error) {
      setLocalLoading(false);
      toast.error("Error while creating finaceverse profile!");
      console.log(
        error?.message,
        "Error while creating finaceverse profile api error"
      );
    }
  }

  async function getAllCountries() {
    let allCountries = await getData(
      `https://comms.globalxchange.io/coin/vault/countries/data/get`
    );
    setAllCountries(allCountries?.countries);
  }

  async function checkUsernameExist() {
    let response = await getData(
      `https://newauth.apimachine.com/v1/user/name/finace/?username=${registrationDetails?.userName}`
    );
    if (response?.usernameAvailable) {
      setUsernameAvailable(true);
    } else {
      setUsernameAvailable(false);
    }
  }

  //renderings

  useEffect(() => {
    getAllCountries();
  }, []);

  useEffect(() => {
    checkUsernameExist();
  }, [registrationDetails?.userName]);

  return (
    <div className={classNames.registration}>
      <div className={classNames.leftContainer}>
        <div className={classNames.topLogo}>
          <img
            src={logoWithName}
            alt="logoWithName"
            onClick={() => history.push("/")}
          />
        </div>
        <div className={classNames.loginContainer}>
          <div className={classNames.inputsContainer}>
            {registrationStep === 2 ? (
              <>
                <div className={classNames.header}>
                  <div className={classNames.title}>
                    Next lets learn a bit more about you
                  </div>
                </div>
                <div className={classNames.inputs}>
                  <div className={classNames.flexContainer}>
                    <EachCustomDropdownCountry
                      dropdown={allCountries}
                      name="Country"
                      title="Select Country"
                      stateValue={registrationDetailsFinaceverse}
                      setState={setRegistrationDetailsFinaceverse}
                      type="obj"
                      stateVal="name"
                      mapVal={{ name: "name", image: "image" }}
                    />
                    <CustomTextInput
                      placeholder="Enter state..."
                      name="state"
                      stateValue={registrationDetailsFinaceverse}
                      setState={setRegistrationDetailsFinaceverse}
                    />
                  </div>
                  <CustomTextInput
                    placeholder="Address..."
                    name="Address"
                    stateValue={registrationDetailsFinaceverse}
                    setState={setRegistrationDetailsFinaceverse}
                  />
                  <div className={classNames.flexContainer}>
                    <CustomTextInput
                      placeholder="City..."
                      name="city"
                      stateValue={registrationDetailsFinaceverse}
                      setState={setRegistrationDetailsFinaceverse}
                    />
                    <CustomTextInput
                      placeholder="Postal code..."
                      name="pincode"
                      stateValue={registrationDetailsFinaceverse}
                      setState={setRegistrationDetailsFinaceverse}
                    />
                  </div>
                  <div
                    className={`${classNames.flexContainer} ${classNames.phoneNumberContainer}`}
                  >
                    <EachCustomDropdown
                      dropdown={countryCodes}
                      name="country"
                      title="Code"
                      stateValue={registrationDetailsFinaceverse}
                      setState={setRegistrationDetailsFinaceverse}
                      type="obj"
                      stateVal="code"
                      mapVal={{ name: "code" }}
                    />
                    <CustomTextInput
                      placeholder="Phone number..."
                      name="PhoneNumber"
                      stateValue={registrationDetailsFinaceverse}
                      setState={setRegistrationDetailsFinaceverse}
                    />
                  </div>
                  <MyEachCustomImageUpload
                    name="ProfilePic"
                    placeholder="Upload Profile"
                    stateValue={registrationDetailsFinaceverse}
                    setState={setRegistrationDetailsFinaceverse}
                  />
                </div>
                <div
                  className={`${classNames.loginBtn} ${
                    localLoading && classNames.notAllowed
                  }`}
                  onClick={createFinaceverseProfile}
                >
                  {localLoading ? "Loading..." : "Next Step"}
                </div>
              </>
            ) : (
              <>
                <div className={classNames.header}>
                  <div className={classNames.title}>
                    Lets get your Finaceverse setup
                  </div>
                  <div className={classNames.desc}>
                    Finaceverse is a universal login system which will be used
                    to authenicate you accross all our apps. Don’t worry its
                    free!
                  </div>
                </div>
                <div className={classNames.inputs}>
                  <div className={classNames.flexContainer}>
                    <CustomTextInput
                      placeholder="First name"
                      name="firstName"
                      stateValue={registrationDetails}
                      setState={setRegistrationDetails}
                    />
                    <CustomTextInput
                      placeholder="Last name"
                      name="lastName"
                      stateValue={registrationDetails}
                      setState={setRegistrationDetails}
                    />
                  </div>
                  <div className={classNames.flexContainer}>
                    <CustomTextInput
                      placeholder="Email"
                      name="email"
                      stateValue={registrationDetails}
                      setState={setRegistrationDetails}
                    />
                    <div className={classNames.usernameAvailable}>
                      <CustomTextInput
                        placeholder="Username"
                        name="userName"
                        stateValue={registrationDetails}
                        setState={setRegistrationDetails}
                      />
                      <div
                        className={classNames.availablitypoint}
                        style={{
                          background:
                            usernameAvailable &&
                            registrationDetails?.userName?.length > 5
                              ? "var(--main-color)"
                              : "",
                        }}
                      ></div>
                    </div>
                  </div>
                  <CustomTextInput
                    type="password"
                    placeholder="Create password"
                    name="password"
                    stateValue={registrationDetails}
                    setState={setRegistrationDetails}
                  />
                  <CustomTextInput
                    type="password"
                    placeholder="Confirm password"
                    name="confirmPassword"
                    stateValue={registrationDetails}
                    setState={setRegistrationDetails}
                  />
                </div>
                <div
                  className={`${classNames.loginBtn} ${
                    localLoading && classNames.notAllowed
                  }`}
                  onClick={newRegistrationAPI}
                >
                  {localLoading ? "Loading..." : "Next Step"}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={classNames.rightContainer}></div>
    </div>
  );
};

export default RegistrationNew;
