import affiliateIcon from "../images/registration/logo/affiliate.svg";
import rocketIcon from "../images/registration/logo/rocket.svg";
import searchIcon from "../images/search.svg";

//layouts
import dashboardIcon from "../images/layout/dashboard.svg";
import walletIcon from "../images/layout/wallet.svg";
import supportIcon from "../images/layout/support.svg";

export const registrationItems = [
  {
    image: affiliateIcon,
    title: "Via An Affiliate",
    desc: "One of other many people who love Finace sent you here",
  },
  {
    image: rocketIcon,
    title: "I Am Pre-Registered",
    desc: "I am an existing client of a firm who on Finace",
  },
  {
    image: searchIcon,
    title: "I Got Here By Myself",
    desc: "Google, social media, etc",
  },
];

export const miniSidebarItems = [
  {
    name: "Dashboard",
    icon: dashboardIcon,
    path: "/dashboard/profile",
    mainPath: "/dashboard",
  },
  {
    name: "Wallet",
    icon: walletIcon,
    path: "/wallet/vaults",
    mainPath: "/wallet",
  },
  {
    name: "Support",
    icon: supportIcon,
    path: "/support",
    mainPath: "/support",
  },
];

export const dashboardSubMenuItems = [
  { name: "Profile", path: "/dashboard/profile" },
  // { name: "KYC", path: "/dashboard/kyc" },
  { name: "Apps", path: "/dashboard/apps" },
  // { name: "Settings", path: "/dashboard/settings" },
];

export const walletSubMenuItems = [
  { name: "Vaults", path: "/wallet/vaults" },
  { name: "Credits", path: "/wallet/credits" },
  { name: "Subscriptions", path: "/wallet/subscriptions" },
  { name: "Referrals", path: "/wallet/referrals" },
  { name: "Withdrawals", path: "/wallet/withdrawals" },
  { name: "Visiblity Tokens", path: "/wallet/visiblitytoken" },
];
