export const APP_CODE = "finace";

export const APP_USER_TOKEN = "app_user_token";

export const COUNTRY = "India";

export const allNav = [
  { name: "Finaceverse", path: "/dashboard/profile/finaceverse" },
  { name: "Client" },
  { name: "Accountant" },
  {
    name: "Accute",
    subMenu: [{ name: "Employee" }, { name: "Firm owner" }],
  },
  { name: "Finaid" },
  { name: "Luca" },
  { name: "MyCabinets" },
];

export const allAppsNav = [
  { name: "Finace Store", path: "/dashboard/apps/finacestore" },
  // { name: "Build An App" },
];

export const allWalletVaultsNavbar = [
  { name: "US Dollars", path: "/wallet/vaults/usd" },
  // { name: "Indian Rupees", path: "/wallet/vaults/inr" },
];
