import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import classNames from "./forgotpassword.module.scss";
import logoWithName from "../../assets/images/logowithname.svg";
import { CustomTextInput } from "../../components/Custom";

const ForgotPassword = () => {
  const history = useHistory();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tokenParam = params.get("token");
    if (tokenParam) {
      setToken(tokenParam);
    }
  }, [location]);

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        "https://newauth.apimachine.com/v1/user/forgot-password",
        {
          appCode: "finace",
          email: email,
          url: "https://finaceverse.io/forgotpassword",
        }
      );
      setLoading(false);
      if (response.data.status) {
        toast.success("Password reset link sent to your email");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error("An error occurred. Please try again.");
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        "https://newauth.apimachine.com/v1/user/reset-password",
        {
          newPassword: newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      if (response.data.success) {
        toast.success("Password reset successfully");
        history.push("/login");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <div className={classNames.mainForgotPasswordContainer}>
      <div className={classNames.leftContainer}>
        <div className={classNames.topLogo}>
          <img
            src={logoWithName}
            alt="logoWithName"
            onClick={() => history.push("/")}
          />
        </div>
        <div className={classNames.forgotPasswordContainer}>
          <div className={classNames.inputsContainer}>
            <div className={classNames.header}>
              <div className={classNames.title}>
                {token ? "Reset Password" : "Forgot Password"}
              </div>
              <div className={classNames.titledesc}>
                {token
                  ? "Enter your new password"
                  : "Enter your email to reset password"}
              </div>
            </div>
            {token ? (
              <form onSubmit={handleResetPassword}>
                <div className={classNames.inputs}>
                  <CustomTextInput
                    type="password"
                    placeholder="New Password"
                    setValue={newPassword}
                    setState={setNewPassword}
                  />
                  <CustomTextInput
                    type="password"
                    placeholder="Confirm New Password"
                    setValue={confirmPassword}
                    setState={setConfirmPassword}
                  />
                </div>
                <button
                  type="submit"
                  className={`${classNames.submitBtn} ${
                    loading && classNames.notAllowed
                  }`}
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Reset Password"}
                </button>
              </form>
            ) : (
              <form onSubmit={handleForgotPassword}>
                <div className={classNames.inputs}>
                  <CustomTextInput
                    placeholder="Email"
                    setValue={email}
                    setState={setEmail}
                  />
                </div>
                <button
                  type="submit"
                  className={`${classNames.submitBtn} ${
                    loading && classNames.notAllowed
                  }`}
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Send Reset Link"}
                </button>
              </form>
            )}
            <div className={classNames.loginBtn}>
              Remember your password?{" "}
              <span onClick={() => history.push("/login")}>Login</span>
            </div>
          </div>
        </div>
      </div>
      <div className={classNames.rightContainer}></div>
    </div>
  );
};

export default ForgotPassword;
