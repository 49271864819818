import React, { useContext, useEffect, useState } from "react";
import classNames from "./dashboardapps.module.scss";
import { useLocation } from "react-router-dom";
import { allAppsNav } from "../../../assets/data/constant";
import axios from "axios";
import { GlobalContext } from "../../../context/Globalcontext";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { ReactComponent as AccountantsIcon } from "../../../assets/images/icons/accountants.svg";
import { ReactComponent as AccuteIcon } from "../../../assets/images/icons/accute.svg";
import { ReactComponent as FinaceverseIcon } from "../../../assets/images/icons/finaceverse.svg";
import { ReactComponent as FinaiassuredIcon } from "../../../assets/images/icons/finaiassured.svg";
import { ReactComponent as LucaIcon } from "../../../assets/images/icons/luca.svg";
import { ReactComponent as MyCabinetIcon } from "../../../assets/images/icons/mycabinet.svg";
import { ReactComponent as FinaidIcon } from "../../../assets/images/icons/mycabinet.svg";

export const DashboardApps = () => {
  const location = useLocation();
  const [selectedSection, setSelectedSection] = useState("Finace Store");

  return (
    <div className={classNames.staffProfile}>
      <div className={classNames.leftContainer}>
        <div className={classNames.allSections}>
          {allAppsNav?.map((eachNav, index) => {
            return eachNav.subMenu?.length > 0 ? (
              <MenuDropdown
                selectedSection={selectedSection}
                eachNav={eachNav}
                setSelectedSection={setSelectedSection}
                index={index}
              />
            ) : (
              <div
                key={eachNav.name + index}
                className={
                  selectedSection === eachNav?.name ||
                  location.pathname?.includes(eachNav?.path)
                    ? classNames.selectedSection
                    : ""
                }
                onClick={() => setSelectedSection(eachNav?.name)}
              >
                <div className={classNames.normalDiv}>{eachNav?.name}</div>
              </div>
            );
          })}
        </div>
        {/* <div
          className={classNames.gradientBtn}
          // onClick={() => history.push("/owner/teams/staff")}
        >
          Go Back
        </div> */}
      </div>
      <div className={classNames.rightContainer}>
        {selectedSection === "Finace Store" ? <FinaceStore /> : ""}
      </div>
    </div>
  );
};

//small components

const MenuDropdown = ({
  selectedSection,
  eachNav,
  setSelectedSection,
  index,
}) => {
  const location = useLocation();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div
      key={eachNav.name + index}
      style={{ padding: "0" }}
      className={
        selectedSection === eachNav?.name ||
        location.pathname?.includes(eachNav?.path)
          ? classNames.selectedSection
          : ""
      }
    >
      <div
        className={classNames.flexDiv}
        onClick={() => {
          setSelectedSection(eachNav?.name);
          setDropdownOpen((prev) => !prev);
        }}
      >
        <div>{eachNav?.name}</div>
        <div>{dropdownOpen ? <TiArrowSortedUp /> : <TiArrowSortedDown />}</div>
      </div>
      <div className={classNames.dropdownDiv}>
        {dropdownOpen &&
          eachNav?.subMenu?.map((eachMenu, index) => {
            return (
              <div className={classNames.eachItem} key={eachMenu?.name + index}>
                {eachMenu?.name}
              </div>
            );
          })}
      </div>
    </div>
  );
};

/* finace store */

const FinaceStore = () => {
  const allApps = [
    { name: "Luca", icon: <LucaIcon /> },
    { name: "Accountants", icon: <AccountantsIcon /> },
    { name: "Accute", icon: <AccuteIcon /> },
    { name: "MyCabinet", icon: <MyCabinetIcon /> },
    { name: "C(ai)FO", icon: <MyCabinetIcon /> },
    { name: "Aicademy", icon: <MyCabinetIcon /> },
  ];

  const allMarketplaces = [
    { name: "Finaid", icon: <FinaidIcon /> },
    { name: "Finace Ventures", icon: <FinaidIcon /> },
  ];

  const allModels = [{ name: "Finaissured", icon: <FinaiassuredIcon /> }];

  return (
    <div className={classNames.finaceStore}>
      <div className={classNames.allApps}>
        <div className={classNames.header}>Apps</div>
        <div className={classNames.content}>
          {allApps?.map((eachApp, index) => {
            return (
              <div className={classNames.eachApp}>
                {eachApp?.icon}
                <div>{eachApp?.name}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={classNames.allApps}>
        <div className={classNames.header}>Marketplaces</div>
        <div className={classNames.content}>
          {allMarketplaces?.map((eachApp, index) => {
            return (
              <div className={classNames.eachApp}>
                {eachApp?.icon}
                <div>{eachApp?.name}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={classNames.allApps}>
        <div className={classNames.header}>Models</div>
        <div className={classNames.content}>
          {allModels?.map((eachApp, index) => {
            return (
              <div className={classNames.eachApp}>
                {eachApp?.icon}
                <div>{eachApp?.name}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
