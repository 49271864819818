import React, { useContext, useEffect, useState } from "react";
import classNames from "./dashboardprofile.module.scss";
import { useHistory, useParams, useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { allNav } from "../../../assets/data/constant";
import { FaCaretDown } from "react-icons/fa";
import breakdown from "../../../assets/images/icons/breakdown.svg";
import lockIcon from "../../../assets/images/sidebar/icons/lock.svg";
import cpuIcon from "../../../assets/images/icons/cpu.svg";
import axios from "axios";
import { GlobalContext } from "../../../context/Globalcontext";
import moment from "moment";
import { CustomTextInput } from "../../../components/Custom";
import { toast } from "react-toastify";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { capitalizeFirstLetter } from "../../../assets/functions";

export const DashboardProfile = () => {
  const { staffemail } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { selectedItemGlobal, setSelectedItemGlobal } =
    useContext(GlobalContext);
  const [selectedSection, setSelectedSection] = useState("Finaceverse");
  const [profileData, setProfileData] = useState({});
  const [profileDataLoading, setProfileDataLoading] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);

  //functions

  function getProfileData() {
    axios
      .get(
        `https://finacebackend.marketsverse.com/api/getTeamPlayerProfile?TeamPlayerID=${selectedItemGlobal?.TeamPlayerID}`
      )
      .then((res) => {
        if (res.data.success) {
          // setProfileData(res?.data?.data?.[0]);
        } else {
          // setProfileData([]);
        }
      });
  }

  async function getStaffRolesOneTeam() {
    setProfileDataLoading(true);
    try {
      let response = await axios.get(
        `https://finacebackend.marketsverse.com/api/getlist?email=${
          localStorage.getItem("userEmail")
            ? localStorage.getItem("userEmail")
            : ""
        }`
      );
      if (
        response?.data?.data?.length > 0 &&
        Array.isArray(response?.data?.data)
      ) {
        setProfileData(response?.data?.data[0]);
      }
      setProfileDataLoading(false);
    } catch (error) {
      setProfileDataLoading(false);
      console.log(error?.message, "Get staff roles one team error");
    }
  }

  //renderings

  useEffect(() => {
    getStaffRolesOneTeam();
    if (selectedItemGlobal) {
      getProfileData();
    }
  }, [localLoading, selectedItemGlobal?.TeamPlayerID]);

  return (
    <div className={classNames.staffProfile}>
      <div className={classNames.leftContainer}>
        <div className={classNames.allSections}>
          {allNav?.map((eachNav, index) => {
            return eachNav.subMenu?.length > 0 ? (
              <MenuDropdown
                selectedSection={selectedSection}
                eachNav={eachNav}
                setSelectedSection={setSelectedSection}
                index={index}
              />
            ) : (
              <div
                key={eachNav.name + index}
                className={
                  selectedSection === eachNav?.name ||
                  location.pathname?.includes(eachNav?.path)
                    ? classNames.selectedSection
                    : ""
                }
                onClick={() => setSelectedSection(eachNav?.name)}
              >
                <div className={classNames.normalDiv}>{eachNav?.name}</div>
              </div>
            );
          })}
        </div>
        {/* <div
          className={classNames.gradientBtn}
          // onClick={() => history.push("/owner/teams/staff")}
        >
          Go Back
        </div> */}
      </div>
      <div className={classNames.rightContainer}>
        {selectedSection === "Finaceverse" ? (
          <Profile
            profileData={profileData}
            profileDataLoading={profileDataLoading}
            setLocalLoading={setLocalLoading}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const Profile = ({ profileData, profileDataLoading, setLocalLoading }) => {
  const [editProfile, setEditProfile] = useState(false);
  const [editedProfile, setEditedProfile] = useState({});

  //functions

  async function saveProfile() {
    try {
      const response = await axios.put(
        "https://finacebackend.marketsverse.com/api/edituserdetails",
        {
          Address: editedProfile?.Address,
          city: editedProfile?.city,
          state: editedProfile?.state,
          pincode: editedProfile?.pincode,
          Country: editedProfile?.Country,
          email: profileData?.email,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response?.data?.success) {
        toast.success(response?.data?.message);
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error("Error while updating user profile!");
      console.log(error?.message, "Save profile error");
    }
    setEditProfile(false);
    setLocalLoading((prev) => !prev);
  }

  useEffect(() => {
    setEditedProfile(profileData);
  }, [profileData]);

  return (
    <>
      <div className={classNames.personalDetails}>
        <div className={classNames.profile}>
          {profileDataLoading ? (
            <Skeleton height={"2.5rem"} width={"2.5rem"} circle />
          ) : (
            <img src={profileData?.ProfilePic} alt={profileData?.name} />
          )}
          <span>
            {profileDataLoading ? (
              <Skeleton height={15} width={150} />
            ) : profileData?.name && profileData?.lastname ? (
              profileData?.name + " " + profileData?.lastname
            ) : profileData?.name ? (
              profileData?.name
            ) : (
              "-"
            )}
          </span>
        </div>
        <div className={classNames.details}>
          <div>
            <div className={classNames.title}>Email</div>
            <div className={classNames.value}>
              {profileDataLoading ? (
                <Skeleton height={15} width={150} />
              ) : profileData?.email ? (
                profileData?.email
              ) : (
                "-"
              )}
            </div>
          </div>
          <div>
            <div className={classNames.title}>Phone</div>
            <div className={classNames.value}>
              {profileDataLoading ? (
                <Skeleton height={15} width={150} />
              ) : profileData?.PhoneNumber ? (
                profileData?.PhoneNumber
              ) : (
                "-"
              )}
            </div>
          </div>
          <div>
            <div className={classNames.title}>Role</div>
            <div className={classNames.value}>
              {profileDataLoading ? (
                <Skeleton height={15} width={150} />
              ) : profileData?.key ? (
                capitalizeFirstLetter(profileData?.key)
              ) : (
                "-"
              )}
            </div>
          </div>
          <div>
            <div className={classNames.title}>Member Since</div>
            <div className={classNames.value}>
              {profileDataLoading ? (
                <Skeleton height={15} width={150} />
              ) : profileData?.createdAt ? (
                moment(profileData?.createdAt).format("MMM Do YY")
              ) : (
                "-"
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={classNames.allDetails}>
        <div className={classNames.header}>
          <div>Address</div>
          <div
            className={classNames.editBtn}
            onClick={() => {
              if (editProfile) {
                setEditedProfile(profileData);
              }
              setEditProfile((prev) => !prev);
            }}
          >
            {editProfile ? "Close" : "Edit"}
          </div>
        </div>
        <div className={classNames.content}>
          <div>
            <div>Street</div>
            <div>
              {editProfile ? (
                <CustomTextInput
                  placeholder="Enter address..."
                  name="Address"
                  stateValue={editedProfile}
                  setState={setEditedProfile}
                />
              ) : profileDataLoading ? (
                <Skeleton height={15} width={150} />
              ) : profileData?.Address ? (
                profileData?.Address
              ) : (
                "-"
              )}
            </div>
          </div>
          <div>
            <div>City</div>
            <div>
              {editProfile ? (
                <CustomTextInput
                  placeholder="Enter city..."
                  name="city"
                  stateValue={editedProfile}
                  setState={setEditedProfile}
                />
              ) : profileDataLoading ? (
                <Skeleton height={15} width={150} />
              ) : profileData?.city ? (
                profileData?.city
              ) : (
                "-"
              )}
            </div>
          </div>
          <div>
            <div>State</div>
            <div>
              {editProfile ? (
                <CustomTextInput
                  placeholder="Enter state..."
                  name="state"
                  stateValue={editedProfile}
                  setState={setEditedProfile}
                />
              ) : profileDataLoading ? (
                <Skeleton height={15} width={150} />
              ) : profileData?.state ? (
                profileData?.state
              ) : (
                "-"
              )}
            </div>
          </div>
          <div>
            <div>Pincode</div>
            <div>
              {editProfile ? (
                <CustomTextInput
                  placeholder="Enter pincode..."
                  name="pincode"
                  stateValue={editedProfile}
                  setState={setEditedProfile}
                />
              ) : profileDataLoading ? (
                <Skeleton height={15} width={150} />
              ) : profileData?.pincode ? (
                profileData?.pincode
              ) : (
                "-"
              )}
            </div>
          </div>
          <div>
            <div>Country</div>
            <div>
              {editProfile ? (
                <CustomTextInput
                  placeholder="Enter country..."
                  name="Country"
                  stateValue={editedProfile}
                  setState={setEditedProfile}
                />
              ) : profileDataLoading ? (
                <Skeleton height={15} width={150} />
              ) : profileData?.Country ? (
                profileData?.Country
              ) : (
                "-"
              )}
            </div>
          </div>
          {editProfile && (
            <div className={classNames.submitBtn} onClick={saveProfile}>
              Save Profile
            </div>
          )}
        </div>
      </div>
    </>
  );
};

//small components

const MenuDropdown = ({
  selectedSection,
  eachNav,
  setSelectedSection,
  index,
}) => {
  const location = useLocation();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div
      key={eachNav.name + index}
      style={{ padding: "0" }}
      className={
        selectedSection === eachNav?.name ||
        location.pathname?.includes(eachNav?.path)
          ? classNames.selectedSection
          : ""
      }
    >
      <div
        className={classNames.flexDiv}
        onClick={() => {
          setSelectedSection(eachNav?.name);
          setDropdownOpen((prev) => !prev);
        }}
      >
        <div>{eachNav?.name}</div>
        <div>{dropdownOpen ? <TiArrowSortedUp /> : <TiArrowSortedDown />}</div>
      </div>
      <div className={classNames.dropdownDiv}>
        {dropdownOpen &&
          eachNav?.subMenu?.map((eachMenu, index) => {
            return (
              <div className={classNames.eachItem} key={eachMenu?.name + index}>
                {eachMenu?.name}
              </div>
            );
          })}
      </div>
    </div>
  );
};
