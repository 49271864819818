import axios from "axios";
import * as jose from "jose";
import { toastify } from "./toastify";
const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
const emailDev = "pavithran@inr.group"; // email of the developer.

//used for image upload
function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

const signJwt = async (fileName, emailDev, secret) => {
  try {
    const jwts = await new jose.SignJWT({ name: fileName, email: emailDev })
      .setProtectedHeader({ alg: "HS512" })
      .setIssuer("gxjwtenchs512")
      .setExpirationTime("10m")
      .sign(new TextEncoder().encode(secret));
    return jwts;
  } catch (error) {
    console.log(error, "kjbedkjwebdw");
  }
};

export function hideEmail(email) {
  // Split the email address into local part and domain part
  const [localPart, domainPart] = email.split("@");

  // Replace characters in the local part with asterisks
  const hiddenLocalPart =
    localPart.charAt(0) +
    "*".repeat(localPart.length - 2) +
    localPart.charAt(localPart.length - 1);

  // Return the hidden email address
  return hiddenLocalPart + "@" + domainPart;
}

export const uploadFileFunc = async (e, setFile, setLoading, eachItem) => {
  setLoading(true);
  const fileName = `${new Date().getTime()}${e?.target?.files[0]?.name?.substr(
    e?.target?.files[0]?.name?.lastIndexOf(".")
  )}`;
  const formData = new FormData();
  const file = renameFile(e.target.files[0], fileName);
  formData.append("files", file);
  const path_inside_brain = "root/";

  const jwts = await signJwt(fileName, emailDev, secret);
  let { data } = await axios.post(
    `https://insurance.apimachine.com/insurance/general/upload`,
    formData,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }
  );

  if (data?.length > 0) {
    // console.log(data[0], "dfile name upload");
    setFile(data[0]?.originalname);
    setLoading(false);
    return data[0]?.urlName;
  } else {
    toastify(`Error while uploading file!`, "error");
    setLoading(false);
  }
};

export function isEmail(input) {
  // Regular expression for a simple email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Test the input against the regular expression
  return emailRegex.test(input);
}

export async function filterDetails(array, value) {
  let result = await array?.filter((eachItem) => {
    return eachItem?.userid === value;
  });
  return result;
}

export function getCurrentDate() {
  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const day = currentDate.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export function areAllFieldsFilled(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (!obj[key]) {
        return false;
      }
    }
  }
  return true;
}

export function firstNameValidation(inputValue) {
  let regex = /^[a-zA-Z\s]*$/; // letters & spaces

  let spaces = (inputValue?.match(/ /g) || []).length;

  if (regex.test(inputValue) && spaces < 2) {
    return true;
  } else {
    return false;
  }
}

export const onlyLettersRegex = /^[A-Za-z\s\.]+$/;

export function capitalizeFirstLetter(word) {
  if (!word) return ""; // Handle empty or undefined input
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}
