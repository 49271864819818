import React from "react";
import { ArrowLeft, ArrowUpCircle, ArrowDownCircle } from "lucide-react";
import styles from "./TransactionsList.module.css";

const TransactionsList = ({
  transactions,
  onBack,
  currencyType,
  balanceObj,
}) => {
  const getTransactionType = (transaction) => {
    if (balanceObj.walletid === transaction.from_wallet) {
      return {
        type: "Debit",
        icon: <ArrowUpCircle size={24} color="#dc3545" />,
        colorClass: styles.debit,
      };
    } else if (balanceObj.walletid === transaction.to_wallet) {
      return {
        type: "Credit",
        icon: <ArrowDownCircle size={24} color="#28a745" />,
        colorClass: styles.credit,
      };
    }
    return {
      type: "Unknown",
      icon: null,
      colorClass: "",
    };
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }).format(date);
  };

  return (
    <div className={styles.transactionsView}>
      <div className={styles.header}>
        <button onClick={onBack} className={styles.backButton}>
          <ArrowLeft size={20} />
          <span style={{ marginLeft: "5px" }}>Back to Vault</span>
        </button>
        <h2 className={styles.title}>Transaction History</h2>
      </div>
      <div className={styles.transactionsList}>
        {transactions.length === 0 ? (
          <div className={styles.noTransactions}>No transactions found</div>
        ) : (
          transactions.map((transaction) => {
            const { type, icon, colorClass } = getTransactionType(transaction);
            return (
              <div key={transaction._id} className={styles.transaction}>
                <div className={styles.transactionInfo}>
                  <div className={styles.transactionIcon}>{icon}</div>
                  <div className={styles.transactionDetails}>
                    <span className={`${styles.transactionType} ${colorClass}`}>
                      {type}
                    </span>
                    <span className={styles.transactionDate}>
                      {formatDate(transaction.createdAt)}
                    </span>
                  </div>
                </div>
                <div className={`${styles.transactionAmount} ${colorClass}`}>
                  {currencyType === "US Dollars" ? "$" : "₹"}
                  {transaction.amount.toLocaleString()}
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default TransactionsList;
