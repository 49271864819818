import React, { useCallback, useEffect, useState } from "react";
import classNames from "./walletvaults.module.scss";
import { useLocation } from "react-router-dom";
import { allWalletVaultsNavbar } from "../../../assets/data/constant";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { ReactComponent as ArrowRightIcon } from "../../../assets/images/icons/arrowright.svg";
import axios from "axios";
import { toast } from "react-toastify";
import { AddFundsPopup } from "./AddFundsPopup";
import { ArrowDownCircle, ArrowUpCircle } from "lucide-react";
import TransactionsList from "./TransactionsList";

// Menu Dropdown Component
const MenuDropdown = ({
  selectedSection,
  eachNav,
  setSelectedSection,
  index,
}) => {
  const location = useLocation();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div
      key={eachNav.name + index}
      style={{ padding: "0" }}
      className={
        selectedSection === eachNav?.name ||
        location.pathname?.includes(eachNav?.path)
          ? classNames.selectedSection
          : ""
      }
    >
      <div
        className={classNames.flexDiv}
        onClick={() => {
          setSelectedSection(eachNav?.name);
          setDropdownOpen((prev) => !prev);
        }}
      >
        <div>{eachNav?.name}</div>
        <div>{dropdownOpen ? <TiArrowSortedUp /> : <TiArrowSortedDown />}</div>
      </div>
      <div className={classNames.dropdownDiv}>
        {dropdownOpen &&
          eachNav?.subMenu?.map((eachMenu, index) => (
            <div className={classNames.eachItem} key={eachMenu?.name + index}>
              {eachMenu?.name}
            </div>
          ))}
      </div>
    </div>
  );
};

// General Vaults Component
const GeneralVaults = ({ currencyType, balanceObj }) => {
  const [isAddFundsPopupOpen, setIsAddFundsPopupOpen] = useState(false);
  const [showTransactions, setShowTransactions] = useState(false);
  const [transactions, setTransactions] = useState([]);

  const handleAddFunds = useCallback(
    async (amount) => {
      try {
        const email = localStorage.getItem("userEmail");
        const response = await axios.post(
          "https://finacebackend.marketsverse.com/api/addbalancetoadmin",
          {
            email,
            amount,
          }
        );

        if (response.data.success) {
          toast.success(
            `Successfully added ${amount} ${
              currencyType === "US Dollars" ? "USD" : "INR"
            } to your account.`
          );
        } else {
          throw new Error(response.data.message || "Failed to add funds");
        }
      } catch (error) {
        toast.error(error.message || "An error occurred while adding funds.");
      } finally {
        setIsAddFundsPopupOpen(false);
      }
    },
    [currencyType]
  );

  const handleShowTransactions = useCallback(async () => {
    try {
      const email = localStorage.getItem("userEmail");
      const response = await axios.get(
        `https://finacebackend.marketsverse.com/api/gettransactions?email=${email}`
      );
      if (response.data.success) {
        console.log(response.data.data, "kjwekdjwedlwecwec");
        setTransactions(response.data.data);
        setShowTransactions(true);
      } else {
        throw new Error(
          response.data.message || "Failed to fetch transactions"
        );
      }
    } catch (error) {
      toast.error(
        error.message || "An error occurred while fetching transactions."
      );
    }
  }, []);

  if (showTransactions) {
    return (
      <TransactionsList
        transactions={transactions}
        onBack={() => setShowTransactions(false)}
        currencyType={currencyType}
        balanceObj={balanceObj}
      />
    );
  }

  return (
    <div className={classNames.generalVault}>
      <div className={classNames.balanceCard}>
        <div className={classNames.header}>Balance</div>
        <div className={classNames.content}>
          <div className={classNames.totalBalance}>
            <span>{currencyType === "US Dollars" ? "$" : "₹"}</span>
            <span>{balanceObj?.amount}</span>
          </div>
          <div className={classNames.btnsContainer}>
            <div className={classNames.blueBtn}>Actions</div>
            <div
              className={classNames.transparentBtn}
              onClick={handleShowTransactions}
            >
              Transactions
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames.allCards}
        onClick={() => setIsAddFundsPopupOpen(true)}
      >
        <div className={classNames.content}>
          <div>Add funds</div>
          <div>
            Add money to your {currencyType === "US Dollars" ? "USD" : "INR"}{" "}
            vault from your bank account
          </div>
        </div>
        <div className={classNames.arrowBtn}>
          <ArrowRightIcon />
        </div>
      </div>
      <div className={classNames.allCards}>
        <div className={classNames.content}>
          <div>Withdraw funds</div>
          <div>
            Withdraw money from your{" "}
            {currencyType === "US Dollars" ? "USD" : "INR"} vault to your bank
            account
          </div>
        </div>
        <div className={classNames.arrowBtn}>
          <ArrowRightIcon />
        </div>
      </div>
      <div className={classNames.allCards}>
        <div className={classNames.content}>
          <div>Transfer funds</div>
          <div>
            Transfer money from your{" "}
            {currencyType === "US Dollars" ? "USD" : "INR"} vault to another
            FinACEverse user
          </div>
        </div>
        <div className={classNames.arrowBtn}>
          <ArrowRightIcon />
        </div>
      </div>
      <AddFundsPopup
        isOpen={isAddFundsPopupOpen}
        onClose={() => setIsAddFundsPopupOpen(false)}
        onSubmit={handleAddFunds}
        currencyType={currencyType}
      />
    </div>
  );
};

// Main WalletVault Component
export const WalletVault = () => {
  const location = useLocation();
  const [selectedSection, setSelectedSection] = useState("US Dollars");
  const [balanceObj, setBalanceObj] = useState(0);

  useEffect(() => {
    const email = localStorage.getItem("userEmail");
    axios
      .get(
        `https://finacebackend.marketsverse.com/api/getbalances?email=${email}`
      )
      .then(({ data }) => {
        if (data.success && data.data.length > 0) {
          console.log(data.data[0], "kjwekdjwedlwecwec");
          setBalanceObj(data.data[0]);
        }
      })
      .catch((error) => {
        toast.error("Failed to fetch balance information");
      });
  }, []);

  return (
    <div className={classNames.staffProfile}>
      <div className={classNames.leftContainer}>
        <div className={classNames.allSections}>
          {allWalletVaultsNavbar?.map((eachNav, index) => {
            return eachNav.subMenu?.length > 0 ? (
              <MenuDropdown
                key={eachNav.name + index}
                selectedSection={selectedSection}
                eachNav={eachNav}
                setSelectedSection={setSelectedSection}
                index={index}
              />
            ) : (
              <div
                key={eachNav.name + index}
                className={
                  selectedSection === eachNav?.name ||
                  location.pathname?.includes(eachNav?.path)
                    ? classNames.selectedSection
                    : ""
                }
                onClick={() => setSelectedSection(eachNav?.name)}
              >
                <div className={classNames.normalDiv}>{eachNav?.name}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={classNames.rightContainer}>
        {selectedSection && (
          <GeneralVaults
            currencyType={selectedSection}
            balanceObj={balanceObj}
          />
        )}
      </div>
    </div>
  );
};

export default WalletVault;
