import React, { useState } from "react";
import classNames from "./walletvaults.module.scss";

export const AddFundsPopup = ({ isOpen, onClose, onSubmit, currencyType }) => {
  const [amount, setAmount] = useState("");

  const handleSubmit = () => {
    const numAmount = parseFloat(amount);
    if (!isNaN(numAmount) && numAmount > 0) {
      onSubmit(numAmount);
      setAmount("");
    }
  };

  if (!isOpen) return null;

  return (
    <div className={classNames.popupOverlay}>
      <div className={classNames.popupContent}>
        <h2>Add Funds</h2>
        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          placeholder={`Enter amount in ${currencyType === "US Dollars" ? "USD" : "INR"}`}
          className={classNames.input}
        />
        <div className={classNames.popupButtons}>
          <button onClick={onClose} className={classNames.cancelButton}>
            Cancel
          </button>
          <button onClick={handleSubmit} className={classNames.submitButton}>
            Add Funds
          </button>
        </div>
      </div>
    </div>
  );
};
